/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@font-face {
    src: url(/fonts/BluuNext-Bolditalic.otf);
    font-family: 'Bluu-Next-Bold';
}

*,
body,
html {
    font-family: 'Noto Sans', sans-serif;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

html {
    font-family: sans-serif;
}

img {
    max-width: 100%;
}

/* loader animation */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeOut 1s ease-out 5s forwards;
}

.loader {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 1.5s infinite ease-in-out;
}

.loader img {
    width: 100%;
    height: auto;
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}


.section {
    margin: 0 auto;
}

#cop26-body a {
    text-decoration: none;
    color: inherit;
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100vw;
}

#back-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    cursor: pointer;
}

/************    SIDEBAR MENU   ***************/

.sidebar-eye,
.sidebar-eye-hover,
.sidebar-eye-open,
.sidebar-eye-hover-open,
.sidebar-eye-white {
    cursor: pointer;
    z-index: 4;
    max-width: 60px;
}

.sidebar-eye,
.sidebar-eye-hover,
.sidebar-eye-white {
    padding-right: 1em;
}

.sidebar-eye-open,
.sidebar-eye-hover-open {
    padding-right: 0.5em;
}

.sidebar {
    height: 100vh;
    width: 100vw;
    position: fixed;
    visibility: hidden;
    overflow-x: hidden;
    z-index: 4;
}

.sidebar-social-row {
    padding: 1.5rem;
}

.sidebar-menu-row {
    padding-top: 4em;
    max-width: 60%;
}

.sidebar-eye-hover,
.sidebar-eye-hover-open,
.sidebar-eye-white {
    display: none;
}

.facebook-icon-hover,
.insta-icon-hover,
.twitter-icon-hover {
    display: none;
}

.show {
    display: block;
}

.hide {
    display: none !important;
}

.sidebar-carousel-container {
    position: absolute;
    visibility: hidden;
    -webkit-transition: 2.5s;
    -o-transition: 2.5s;
    transition: 2.5s;
    top: 0;
    right: -100%;
    height: 100%;
    width: 65%;
}

.sidebar-carousel {
    position: relative;
    margin: auto;
    background-color: #fff;
    height: 100%;
    width: 100%;
}

.sidebar-menu {
    position: absolute;
    top: 0;
    right: -100%;
    background-color: #fff;
    visibility: hidden;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    width: 38%;
    height: 100%;
}

#sidebar-social-row {
    margin-left: 0.6em;
}

#sidebar-social-row .sidebar-col:last-child {
    padding-right: 1.6em;
}

#facebook-icon {
    margin-right: 0.8em;
}

.sidebar-menu-item {
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 3px 0;
    margin: 8px 0;
    cursor: pointer;
    width: auto;
}

.sidebar-menu-item-text {
    padding-right: 0.5em;
}

.sidebar-menu-item:hover {
    font-weight: 600;
}

.sidebar-menu-item-arrow {
    position: absolute;
    visibility: hidden;
    margin-left: 0.8em;
}

.sidebar-menu .sidebar-menu-row {
    margin-left: 4vw;
}

#welcome-div:hover,
#climat-liter-div:hover {
    background-color: #000;
    color: #fff;
}

#cornerstone-div:hover,
#close-of-div:hover {
    background-color: #29ff42;
}

#making-waves-div:hover,
#after-november-div:hover {
    background-color: #e2e2e2;
}

.social-icons-a {
    margin-right: 1em;
}

.social-icons {
    width: 1em;
    height: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.social-img {
    width: 1em;
    height: 1em;
}

.sidebar-menu-slide {
    visibility: visible;
    right: 0;
}

.sidebar-carousel-slide {
    visibility: visible;
    right: 35%;
}

.mySlides {
    height: 100%;
    width: 100%;
}

.mySlides img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0% 90%;
    object-position: 0% 90%;
}

/***********      MAIN MENU    **********/

.main-menu-section {
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
}

.main-menu-row {
    height: 80vh;
    width: 100vw;
}

.main-menu-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
}

.main-menu-item-text {
    font-size: 0px;
    font-weight: 400;
    margin: 8px 0;
    cursor: pointer;
    line-height: 40px;
    z-index: 2;
    margin-left: 0.3em;
}

.main-menu-item-3 {
    padding-left: 8em;
}

.main-menu-item-4 {
    margin-left: -1em;
}

.main-menu-item-5 {
    padding-left: 4em;
}

.main-menu-item-6 {
    padding-left: 3em;
}

.main-menu-item-arrow {
    position: absolute;
    visibility: hidden;
    margin-right: 0.5em;
}

.main-background-div {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: -1;
    -webkit-animation-name: fade;
    animation-name: fade;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.main-line {
    width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
}

.main-img {
    max-width: 30vw;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
}

.main-welcome-1 {
    position: absolute;
    left: 0%;
    bottom: 0%;
}

.main-welcome-2 {
    position: absolute;
    right: 0;
    bottom: 20%;
}

.main-making-waves-1 {
    position: absolute;
    left: 0%;
    bottom: 8%;
}

.main-making-waves-2 {
    position: absolute;
    right: 0;
    top: 16%;
}

.main-sustainable-1 {
    position: absolute;
    left: 0%;
    bottom: 0%;
}

.main-sustainable-2 {
    position: absolute;
    right: 0%;
    bottom: 16%;
}

.main-after-november-1 {
    position: absolute;
    left: 0%;
    top: 38%;
}

.main-after-november-2 {
    position: absolute;
    right: 0%;
    top: 48%;
}

.main-climate-literacy-1 {
    position: absolute;
    left: 0%;
    top: 36%;
}

.main-climate-literacy-2 {
    position: absolute;
    right: 0%;
    top: 22%;
}

.main-close-of-play-1 {
    position: absolute;
    left: 0%;
    top: 22%;
}

.main-close-of-play-2 {
    position: absolute;
    right: 0%;
    top: 26%;
}

/*Snap scroll*/
html {
    -webkit-scroll-snap-type: y proximity;
    -ms-scroll-snap-type: y proximity;
    scroll-snap-type: y proximity;
    -webkit-scroll-snap-points-y: repeat(100vh);
    -ms-scroll-snap-points-y: repeat(100vh);
    scroll-snap-points-y: repeat(100vh);
}

.snap.start {
    scroll-snap-align: start;
}

.snap.center {
    scroll-snap-align: center;
}

.snap.end {
    scroll-snap-align: end;
}

.snap.none {
    scroll-snap-align: none;
}
